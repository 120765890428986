/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useRef, useState } from "react";

// react-router-dom components
import { Link, Navigate, useNavigate } from "react-router-dom";
import axios from "axios";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/cloudify/background.jpg";
import { AUTHURL } from "utils/constants";
import { DOMAIN } from "utils/constants";
import { SOCKET_URL } from "utils/constants";
import { useDispatch } from "react-redux";
import { setSnackbar } from "store/appSlice";
import jwt_decode from "jwt-decode";
import { RECONNECT_SOCKET_CODE } from "utils/constants";
import ReCAPTCHA from "react-google-recaptcha";
import  {SITEKEY}  from "utils/constants";
import { setAuthenticated } from "store/appSlice";


function Basic() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const [socketConnected, setSocketConnected] = useState(false);
  const [captchaToken, setCaptchaToken] = useState("");
  const captchaRef = useRef();
  let token = localStorage.getItem("accessToken");
  const [formErrors, setFormErrors] = useState({
    email: "",
    password: "",
  });
  const sitekey = SITEKEY;
  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; 
    return regex.test(email);
  };
  const validatePassword = (password) => {
    const regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/; 
    return regex.test(password);
  };
  let decoded;  
  try {
    if (token  !== null) decoded = jwt_decode(token);
  } catch (error) {
    console.error("Failed to decode token", error);
  }
  // if (token !== null) decoded = jwt_decode(token);
  let latestDate = new Date();

  const setCookies = (cookieName, cookieValue) => {
    document.cookie = `${cookieName}=${cookieValue};domain=${DOMAIN};path=/`;
  };

  const changeCredentials = (e) => {
    setCredentials({ ...credentials, [e.target.id]: e.target.value });
  }; 
  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);    
  
  };  
  const signIn = async () => {
    setFormErrors({ email: "", password: "" });

    if (!credentials.email) {
      setFormErrors((prev) => ({ ...prev, email: "Email is required." }));
    }  else if (!validateEmail(credentials.email)) {
      setFormErrors((prev) => ({ ...prev, email: "Invalid email format." }));
    }

    if (!credentials.password) {
      setFormErrors((prev) => ({ ...prev, password: "Password is required." }));
    } else if (!validatePassword(credentials.password)) {
      setFormErrors((prev) => ({
        ...prev,
        password: "Password must be at least 8 characters.",
      }));
    }

    if (!credentials.email || !credentials.password || formErrors.email || formErrors.password) {
      return;
    }
    try {
      setLoading(true);
      if (credentials.email === "" || credentials.password === "")
        throw { message: "All params required", response: { statusText: "Something went wrong" } };
      const user_data = {
        email: credentials.email,
        password: credentials.password,
        'g-recaptcha-response': captchaToken, 
      };
      const resp = await axios.post(`${AUTHURL}/user/login`,user_data)
      //   {
      //   ...credentials,
      //   captchaToken
      // });
      let info = resp.data.data;
      navigate("/verify-otp", { replace: true });
      dispatch(setAuthenticated(true));
      captchaRef.current.reset();
      // window.location.replace("/");
    } catch (error) {
      console.log(error);
      dispatch(
        setSnackbar({
          open: true,
          type: "warning",
          title: error?.response?.statusText,
          content: error.response.data ? error.response.data.message : error.message,
        })
      );
      captchaRef.current.reset(); 
    } finally {
      setLoading(false);
    }
  };

  const passwordEnteredFunction = (e) => {
    if (e.key == "Enter") {
      signIn();
    }
  };

  let ws = useRef(null);
  let interval = useRef(null);
  // var ws;
  // var interval;
  function WebSocketTest() {
    if ("WebSocket" in window) {
      console.warn("WebSocket is supported by your Browser!");

      // open a web socket
      ws.current = new WebSocket(
        `${SOCKET_URL}/ws/check-org/?org_name=${window.location.host.split(".")[0]}`
      );

      ws.current.onopen = function () {
        // Web Socket is connected, send data using send()
        console.warn("Message is sent...");
        ws.current.send("isOrgCreated");
      };

      ws.current.onmessage = function (evt) {
        let socketInfo = JSON.parse(evt.data);
        console.log("Socket connected successfully", socketInfo);
        setSocketConnected(socketInfo.created);
      };

      ws.current.onclose = function (e) {
        console.log("Socket is closed. Reconnect will be attempted in 5 second.", e.code);
        if (e.code === RECONNECT_SOCKET_CODE) {
          interval.current = setTimeout(function () {
            WebSocketTest();
            onmessage;
            console.log("interval-----", interval);
            clearTimeout(interval);
          }, 5000);
        }
      };
    } else {
      // The browser doesn't support WebSocket
      console.warn("WebSocket not supported by your Browser!");
    }
  }

  useEffect(() => {
    WebSocketTest();

    return () => {
      ws.current?.close();
      clearTimeout(interval.current);
    };
  }, []);

  if (decoded && decoded.exp * 1000 > latestDate.getTime()) return <Navigate to={"/"} />;

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-4}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white">
            Sign in
          </MDTypography>
        </MDBox>
        <MDBox py={8} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                id="email"
                fullWidth
                value={credentials.email}
                error={!!formErrors.email}
                onChange={changeCredentials}
              />
               {formErrors.email && (
                <MDTypography
                variant="caption"
                style={{
                  color: "red",
                  fontWeight: "400",
                  marginTop: "5px",
                  fontSize: "12px",
                  display: "inline",
                }}
                >
                  {formErrors.email}
                </MDTypography>
              )}
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                id="password"
                fullWidth
                value={credentials.password}
                onChange={changeCredentials}
                onKeyDown={passwordEnteredFunction}
                error={!!formErrors.password}
              />
             {formErrors.password && (
                <MDTypography
                  variant="caption"
                  style={{
                    color: "red",
                    fontWeight: "400",
                    marginTop: "5px",
                    fontSize: "12px",
                    display: "inline",
                  }}
                >
                  {formErrors.password}
                </MDTypography>
              )}
            </MDBox>
            <MDBox
            mb={1}
            px={1.5}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              maxWidth: '400px', 
              transform: 'scale(0.9)',
            }}
            >
              <ReCAPTCHA
                sitekey={sitekey} 
                onChange={handleCaptchaChange}
                ref={captchaRef} 
              />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                disabled={loading || !socketConnected || !captchaToken }
                onClick={signIn}
              >
                {loading || !socketConnected ? "loading..." : "sign in"}
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              {/* <MDTypography variant="button" color="text">
                Don&apos;t have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/sign-up"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign up
                </MDTypography>
              </MDTypography> */}
            </MDBox>
            <MDBox textAlign="center">
              <MDTypography
                component={Link}
                to="/reset"
                variant="button"
                color="info"
                fontWeight="medium"
                textGradient
              >
                Forgot Password
              </MDTypography>
            </MDBox> 
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
