/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router-dom components
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

// @mui material components
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/cloudify/background.jpg";
import { AUTHURL } from "utils/constants";
import { useDispatch } from "react-redux";
import { setSnackbar } from "store/appSlice";
import jwt_decode from "jwt-decode";

function XrayIntegration() {
  const dispatch = useDispatch();
  const [credentials, setCredentials] = useState({
    orgName: "",
    clientDomain: "",
    clientId: "",
    clientSecret: "",
  });
  const [loading, setLoading] = useState(false);

  let token = localStorage.getItem("accessToken");
  let decoded;
  if (token !== null) decoded = jwt_decode(token);
  let latestDate = new Date();

  const changeCredentials = (e) => {
    setCredentials({ ...credentials, [e.target.id]: e.target.value });
  };

  const signIn = async () => {
    try {
      setLoading(true);
      if (
        !credentials.orgName ||
        !credentials.clientDomain ||
        !credentials.clientId ||
        !credentials.clientSecret
      )
        throw { message: "All params required", response: { statusText: "Something went wrong" } };
        const payload = {
          org_name: credentials.orgName,
          xray_client_domain: credentials.clientDomain,
          xray_client_id: credentials.clientId,
          xray_client_secret: credentials.clientSecret,
        };
    
      const resp = await axios.post(`${AUTHURL}organization/organization-authenticate-xray`, payload);
      console.log(resp);
      
      window.location.replace("/admin-settings");
    } catch (error) {
      console.log(error);
      dispatch(
        setSnackbar({
          open: true,
          type: "warning",
          title: error?.response?.statusText,
          content: error.message,
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const passwordEnteredFunction = (e) => {
    if (e.key === "Enter") {
      signIn();
    }
  };

  if (decoded && decoded.exp * 1000 > latestDate.getTime()) return <Navigate to={"/"} />;

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-4}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white">
            Xray integration
          </MDTypography>
        </MDBox>
        <MDBox py={8} px={3}>
          <MDBox component="form" role="form">
          <MDBox mb={2}>
              <MDInput
                type="text"
                label="Org name"
                id="orgName"
                fullWidth
                value={credentials.email}
                onChange={changeCredentials}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="url"
                label="Xray client domain"
                id="clientDomain"
                fullWidth
                value={credentials.email}
                onChange={changeCredentials}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Xray client id"
                id="clientId"
                fullWidth
                value={credentials.password}
                onChange={changeCredentials}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Xray client secret"
                id="clientSecret"
                fullWidth
                value={credentials.confirmPassword}
                onChange={changeCredentials}
                onKeyDown={passwordEnteredFunction}
              />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                disabled={loading}
                onClick={signIn}
              >
                {loading ? "loading..." : "submit"}
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default XrayIntegration;
